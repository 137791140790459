.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dashboard {
  padding: 20px;
}

.mini-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.mini-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  flex: 1;
  min-width: 120px; /* Adjust based on your preference */
}

.bar-chart-placeholder {
  margin-top: 20px;
  background-color: #f0f0f0; /* Placeholder style */
  padding: 20px;
  text-align: center;
}

.toggle-fullscreen {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

@media (max-width: 600px) {
  .mini-cards-container {
    flex-direction: column;
    align-items: center;
  }
}
