/* A | B | C | D | E | F | G | H | I | J | K | L | M */
/* N | O | P | Q | R | S | T | U | V | W | X | Y | Z */

/* CSS is generally organized alphabetically */
/* and by type within class property */

  /* TYPES: */
  /* Color */ 
  /* Display & Box Model */
  /* Positioning & Size */ 
  /* Text */ 
  /* Other */

/**
 * Styles for the response button within the response modal.
 */
 .response-button {
  /* Color */ 
  background-color: var(--main-color); /* Setting background color using custom property */
  color: white; /* Setting text color */
  transition: background-color 0.3s ease; /* Adding transition effect to background color */
  /* Display & Box Model */
  display: block; /* Making button a block-level element */
  border: none; /* Removing border */
  border-radius: 20px; /* Rounding corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adding shadow effect */
  /* Positioning & Size */
  margin: 20px auto; /* Centering the button horizontally */
  padding: 10px 20px; /* Adding internal padding */
  /* Text */ 
  font-size: 16px; /* Setting font size */
  /* Other */
  cursor: pointer; /* Changing cursor to pointer on hover */
}

/**
 * Styles for form labels within the response modal.
 */
.response-form label {
  /* Display & Box Model */
  display: block; /* Making labels block-level elements */
  /* Positioning & Size */ 
  margin-bottom: 10px; /* Adding spacing below labels */
}

/**
 * Styles for form inputs, selects, and textareas within the response modal.
 */
.response-form input,
.response-form select,
.response-form textarea {
  /* Display & Box Model */
  border: 1px solid #ccc; /* Adding border */
  border-radius: 4px; /* Rounding corners */
  /* Positioning & Size */ 
  padding: 8px; /* Adding internal padding */
  margin-top: 5px; /* Adding spacing above elements */
  width: 100%; /* Making inputs, selects, and textareas full width */
}

/**
 * Styles for the response modal component.
 */
 .response-modal {
  /* Color */ 
  background: white; /* Setting background color */
  /* Display & Box Model */
  border: 1px solid black; /* Adding border */
  border-radius: 8px; /* Rounding corners */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Adding shadow effect */
  /* Positioning & Size */ 
  margin: auto; /* Centering the modal horizontally */
  max-width: 500px; /* Limiting maximum width */
  padding: 40px; /* Adding internal padding */
  position: relative; /* Positioning context for child elements */
  top: 50%; /* Moving element down by half of its height */
  transform: translateY(-50%); /* Adjusting vertical position to center */
}


